export default{

  namespaced:true,
  state:() => ({
    impuesto:{
      id:null,
      cuota:6,
      abrev:'iva',
      estado_id:null,
      divisa_id:null,
      divisa:null,
      estado:null,
      nota:''
    },

    impuestos:[]
  }),

  getters:{
    draft(state){
      return clone(state.impuesto)
    }
  },

  mutations:{
      clear(state){
        state.impuesto  = {
          id:null,
          cuota:6,
          abrev:'iva',
          estado_id:null,
          divisa_id:null,
          divisa:null,
          estado:null,
          nota:''

        }
      },

      setImpuesto(state,impuesto){
        state.impuesto = impuesto
      },


      setImpuestos(state,impuestos){
        state.impuestos = impuestos
      },

      capture(state,impuesto_id){

        let i = state.impuestos.findIndex(val => val.id === impuesto_id)

        if(i != -1){
          state.impuesto = state.impuestos[i]
        }

      },



      update(state,impuesto){
        let i = state.impuestos.findIndex((v) => v.id === impuesto.id)

        if(i != -1){
          state.impuestos[i] = impuesto
        }

        if(state.impuesto.id === impuesto.id){
          state.impuesto = impuesto
        }

      },

      put(state,impuesto_id){
        let i = state.impuestos.findIndex((v) => v.id === impuesto_id)
        if(i != -1){
          state.impuestos.splice(i,1)
        }
      },
      push(state,impuesto){

        if(impuesto){
          state.impuestos.push(impuesto)
        }

      }


  },

  actions:{

    fetch({state,commit},impuesto_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/impuestos/${impuesto_id}/get/fetch-data`).then(({data}) => {

          commit('setImpuesto',data)
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    fetchData({state,commit},filter){
      return new Promise((resolve, reject) => {
        axios.post(`/api/impuestos/get/fetch-data`,filter).then(({data}) => {
          commit('setImpuestos',data.impuestos)
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){
          
          axios.put(`/api/impuestos/${datos.id}`,datos).then(({data}) => {
            if(data.result){
              commit('update',data.impuesto)
            }

            resolve(data)
          }).catch(e => reject(e))

        }else{
          axios.post(`/api/impuestos`,datos).then(({data}) => {
            
            if(data.result){
                commit('push',data.impuesto)
            }
            resolve(data)
          }).catch(e => reject(e))


        }
      })
    },


    eliminar({commit},impuesto_id){


      return new Promise((resolve, reject) => {
        
        axios.delete(`/api/impuestos/${impuesto_id}`).then(({data}) => {
          if(data.result){
                  commit('put',impuesto_id)
          }
          resolve(data)
        }).catch(e  => reject(e))


      })
    }

  }

}